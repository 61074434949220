const propertyData = {
    "url": "https://3460oakcrestdr.com/",
    "agent": {
      "name": "Aubrey Martin",
      "phone": "503.443.8889",
      "email": "aubrey@aubreymartin.com"
    },
    "full_address": {"street": "8610 SW 147th Terrace, #102", "city": "Beaverton", "state":"Oregon", "zipcode": "97007"},
    "display_address": "8610 SW 147th Terrace, #102",
    "property": {
      "listing_price": "$279,888",
      "bedrooms": "2",
      "baths": "2.5",
      "sq_feet": "1078",
      "lot_size": "N/A",
      "rmls": "20190857",
      "built_in": "2005",
      "blurb": "Located in quiet and convenient Sexton Mtn, this beautiful townhome features newer SS appliances, maple cabinets, slab-granite countertops, central a/c and newly refinished maple hardwood floors. The master bath boasts relaxing soaking tub.  New 3 cm quartz counters in both bathrooms. Fresh interior paint and new premium carpet upstairs.  Private patio expands living space; great for entertaining!",
      "ammenities": [
        
      ]
    },
    "image_count": 18,
    "opens": [
    ],
    "status": "",
    "google": {
        "map": {
            "lat": "45.538309",
            "lng": "-123.131938"
        },
        "maps": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2798.630091101096!2d-122.82956558444275!3d45.45710977910084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54950db894771c41%3A0x4f9be26c8f17ad01!2s8610%20SW%20147th%20Terrace%20%23102%2C%20Beaverton%2C%20OR%2097007!5e0!3m2!1sen!2sus!4v1581018880577!5m2!1sen!2sus",
        "youtube":"NFvtgiX9Pok"
    }
  }

export default propertyData;

